// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { useSelector } from "react-redux";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const userContext = useSelector((state) => state.userAccounts.userContext);

  const filterMenuItems = (item) => {
    const hasItemFeature = item.feature ? true : false;
    const isFeatureEnabled = userContext.features.some((p) => p.name === item.feature);

    if (hasItemFeature) {
      if (!isFeatureEnabled) {
        return null;
      }
    }

    if (item.requiredPermission) {
      if (
        userContext.userAccount.permissions.some(
          (p) => p === item.requiredPermission
        )
      ) {
        return item;
      }
      return null;
    }

    if (item.children) {
      const newItem = { ...item };
      newItem.children = item.children.map((child) => {
        return filterMenuItems(child);
      });
      if (newItem.children.some((child) => child !== null)) {
        newItem.children = newItem.children.filter((child) => child !== null);
        return newItem;
      }
      return null;
    }

    return item;
  };

  let filteredMenuItems = menuItem.items.map((item) => {
    return filterMenuItems(item);
  });
  filteredMenuItems = filteredMenuItems.filter((item) => item !== null);
  const navItems = filteredMenuItems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;

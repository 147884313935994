import { useRoutes } from "react-router-dom";

// routes
import OtherRoutes from "./OtherRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import AutomationRoutes from "./AutomationRoutes";
import DiagnosticRoutes from "./DiagnosticRoutes";
import MessagingRoutes from "./MessagingRoutes";
import LinkerRoutes from "./LinkerRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    AuthenticationRoutes,
    AutomationRoutes,
    DiagnosticRoutes,
    OtherRoutes,
    MessagingRoutes,
    LinkerRoutes,
  ]);
}

import { useEffect } from "react";
import { useSelector } from "react-redux";

import { HttpClient } from "@gearcode/react-components/lib/Services";

const HttpClientContext = () => {
  const customTenant = useSelector(
      (state) => state.customTenant
  );

  //TODO: zrobić CustomUserContextHttpClient
  useEffect(() => {
    if (customTenant.customTenantEnabled) {
      HttpClient.defaultHeaders["gc-custom-tenant"] = customTenant.customTenant;
      if (customTenant.customScope){
        HttpClient.defaultHeaders["gc-custom-scope"] = customTenant.customScope;
      }
      else{
        delete HttpClient.defaultHeaders["gc-custom-scope"];
      }
    } else {
      delete HttpClient.defaultHeaders["gc-custom-tenant"];
      delete HttpClient.defaultHeaders["gc-custom-scope"];
    }
  }, [customTenant]);

  return <></>;
};

export default HttpClientContext;

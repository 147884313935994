import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import userAccountsReducer from "./userAccountsReducer";
import customTenantReducer from "./customTenantReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  userAccounts: userAccountsReducer,
  customTenant: customTenantReducer,
});

export default reducer;

import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { HttpClient } from "@gearcode/react-components/lib/Services";
import DashboardContext from "contexts/DashboardContext";
import PersonIcon from "@mui/icons-material/Person";
import { USER_CONTEXT_CHANGED } from "store/actions";
import {
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

const UserContextSwitcher = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const dashboardContext = useContext(DashboardContext);
  const { t } = useTranslation(["GcDashboard-UserAccountsLoader"]);

  const [isLoading, setIsLoading] = useState(false);
  const currentUserAccount = useSelector(
    (state) => state.userAccounts.currentUserAccount
  );
  const currentUserScope = useSelector(
    (state) => state.userAccounts.currentUserScope
  );

  useEffect(() => {
    let isAccountSelected = currentUserAccount.accountKey ? true : false;
    if (!isAccountSelected) {
      return;
    }

    if (isLoading) {
      return;
    }

    var timeout = setTimeout(() => {
      setIsLoading(true);
    }, 1000);

    async function getUserContext() {
      try {
        const response = await HttpClient.send({
          url: `${dashboardContext.apiBaseUrl}/features`,
        });

        const features = await response.json();
        clearTimeout(timeout);

        const userContext = {
          userAccount: currentUserAccount,
          userScope: currentUserScope,
          features: features,
        };

        dispatch({ type: USER_CONTEXT_CHANGED, userContext });
      } finally {
        clearTimeout(timeout);
        setIsLoading(false);
      }
    }

    getUserContext();
  }, [currentUserAccount, currentUserScope]);

  const loader = (
    <Dialog
      open={isLoading}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <PersonIcon
            fontSize="large"
            sx={{ color: theme.palette.secondary.main }}
          ></PersonIcon>
          <Typography gutterBottom variant="h3" textAlign="center">
            {t("SwitchingUserContext", "Switching user context")}
          </Typography>
          <LinearProgress sx={{ width: "100%" }}></LinearProgress>
        </Stack>
      </DialogContent>
    </Dialog>
  );

  return loader;
};

export default UserContextSwitcher;

import { Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const UserScope = ({ scope, color, onClick }) => {
  const theme = useTheme();

  return (
    <Chip
        color={color || "secondary"}
        size="medium"
        sx={{ width: "100%", '&:hover': {
            background: theme.palette.gearCodePrimary.main,
            cursor: "pointer",
          }
        }}
        label={scope}
        onClick={onClick}/>
  );
};

export default UserScope;

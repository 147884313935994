import { useTheme } from "@mui/material/styles";
import logo from "assets/images/logo.png";

const Logo = () => {
  const theme = useTheme();

  return (
    <img src={logo} alt="GearCode" width="100" style={{objectFit: "cover"}} />
  );
};

export default Logo;

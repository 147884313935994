import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const SendMessage = Loadable(
    lazy(() => import("components/messaging/sender"))
);
const Outbox = Loadable(
    lazy(() => import("components/messaging/outbox"))
);
const Contacts = Loadable(
    lazy(() => import("components/messaging/contacts"))
);
const ContactGroups = Loadable(
    lazy(() => import("components/messaging/contactGroups"))
);

const MessagingRoutes = {
    path: "/messaging",
    element: <MainLayout />,
    children: [
        {
            path: "/messaging/messages/send",
            element: <SendMessage />,
        },
        {
            path: "/messaging/messages/outbox",
            element: <Outbox />,
        },
        {
            path: "/messaging/contacts",
            element: <Contacts />,
        },
        {
            path: "/messaging/contacts/groups",
            element: <ContactGroups />,
        },
    ],
};

export default MessagingRoutes;

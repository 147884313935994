import * as actionTypes from './actions';

export const initialState = {
    customTenant: '',
    customScope: null,
    customTenantEnabled: false,
}

const customTenantReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CUSTOM_TENANT:
            return {
                ...state,
                customTenant: action.customTenant,
                customScope: action.customScope
            };
        case actionTypes.CUSTOM_TENANT_ENABLED_CHANGED:
            return {
                ...state,
                customTenantEnabled: action.customTenantEnabled
            };
        default:
            return state;
    }
};

export default customTenantReducer;
import * as actionTypes from "./actions";
import { HttpClient } from "@gearcode/react-components/lib/Services";
import {
  CookieManager,
  COOKIENAME_CURRENTUSERACCOUNT,
} from "components/common/CookieManager";

export const initialState = {
  userAccounts: [],
  currentUserAccount: {},
  currentUserScope: undefined,
  userContext: { userAccount: {}, userScope: "", features: [] },
};

const userAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_ACCOUNTS_LOADED:
      return {
        ...state,
        userAccounts: action.userAccounts,
      };

    case actionTypes.CURRENT_USER_ACCOUNT_CHANGED: {
      CookieManager.setCookie(
        COOKIENAME_CURRENTUSERACCOUNT,
        action.currentUserAccount.accountKey
      );

      return {
        ...state,
        currentUserAccount: action.currentUserAccount,
      };
    }

    case actionTypes.CURRENT_USER_SCOPE_CHANGED: {
      HttpClient.defaultHeaders["scope"] = action.currentUserScope;

      return {
        ...state,
        currentUserScope: action.currentUserScope,
      };
    }

    case actionTypes.USER_CONTEXT_CHANGED:
      return {
        ...state,
        userContext: action.userContext,
      };

    default:
      return state;
  }
};

export default userAccountsReducer;


import NotificationManager, {Constants} from "./NotificationManager";
import {useEffect, useState} from "react";
import {Alert, Fade, Stack} from "@mui/material";
function NotificationsContainer(){
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        NotificationManager.addChangeListener((notificationsList) => {
            setNotifications(notificationsList);
        });
        return () => {
            NotificationManager.removeChangeListener();
        };
    }, []);

    const warningNotification = (message) => (
        <Alert
            severity="warning"
            variant="filled"
            sx={{width: "100%"}}
        >
            {message || "Warning"}
        </Alert>
    );
    const errorNotification = (message) => (
        <Alert
            severity="error"
            variant="filled"
            sx={{width: "100%"}}
        >
            {message || "Error"}
        </Alert>
    );
    const successNotification = (message) => (
        <Alert
            severity="success"
            variant="filled"
            sx={{width: "100%"}}
        >
            {message || "Success"}
        </Alert>
    );
    const infoNotification = (message) => (
        <Alert
            severity="info"
            variant="filled"
            sx={{width: "100%"}}
        >
            {message || "Info"}
        </Alert>
    );

    const getNotification = (type, message) => {
        switch (type) {
            case Constants.WARNING:
                return warningNotification(message);
            case Constants.ERROR:
                return errorNotification(message);
            case Constants.SUCCESS:
                return successNotification(message);
            case Constants.INFO:
                return infoNotification(message);
            default:
                return infoNotification(message);
        }

    }

    return (
        <Stack sx={{position: "fixed", bottom: 24, right: 24}} spacing={1} direction="column-reverse">
            {notifications.map((notification) => (
                <Fade in={notification.in} key={notification.id}>
                    {getNotification(notification.messageType, notification.message)}
                </Fade>
                    ))}
        </Stack>
        );
}

export default NotificationsContainer;
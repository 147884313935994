import config from "../../config";

const CookieManager = {
  getCookie: (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  },

  setCookie: (name, value) => {
    const cookieString = `${name}=${value};path=${config.basename}`;
    document.cookie = cookieString;
  },
};

export default CookieManager;
export { CookieManager };
export const COOKIENAME_CURRENTUSERACCOUNT = "gc.dashboard.useraccountkey";

import {EventBus} from "@gearcode/react-components/lib/Services";


const Constants = {
    INFO: 'notification-manager-info',
    WARNING: 'notification-manager-warning',
    ERROR: 'notification-manager-error',
    SUCCESS: 'notification-manager-success',
    CHANGE: 'notification-change',
}
class NotificationManager{
    constructor(){
        this.notificationsList = [];
    }
    create(type, message, setOnTop){
        const id = Math.random().toString(36);
        const notification = {
            messageType: type,
            message: message,
            id: id,
            duration: 3000,
            in: true
        };
        setOnTop ? this.notificationList = [notification, ...this.notificationList] : this.notificationsList = [...this.notificationsList, notification];
        this.emitChange();
        setTimeout(() => {
            this.notificationsList = this.notificationsList.map((notification) => {
                if(notification.id === id){
                    notification.in = false;
                }
                return notification;
            });
            this.emitChange();
            setTimeout(() => {
                this.notificationsList = this.notificationsList.filter((notification) => notification.id !== id);
                this.emitChange();
            }, 100);
        }, 3000);
        return id;
    }
    info(message, setOnTop = false){
        return this.create(Constants.INFO, message, setOnTop);
    }
    warning(message, setOnTop = false){
        return this.create(Constants.WARNING, message, setOnTop);
    }
    error(message, setOnTop = false){
        return this.create(Constants.ERROR, message, setOnTop);
    }
    success(message, setOnTop = false){
        return this.create(Constants.SUCCESS, message, setOnTop);
    }

    emitChange(){
        EventBus.dispatch(Constants.CHANGE, this.notificationsList);
    }

    addChangeListener(callback){
        EventBus.on(Constants.CHANGE, callback);
    }
    removeChangeListener(callback){
        EventBus.remove(Constants.CHANGE, callback);
    }
}

export default new NotificationManager();
export {Constants};
const permissions = {
  // default dashboard permissions
  DASHBOARD_APP: "DASHBOARD_APP",
  ASL_INDEX_LOGS: "ASL_INDEX_LOGS",
  ASL_PRODUCT_FEED_LOGS: "ASL_PRODUCT_FEED_LOGS",
  AUTOMATION_PIPELINES: "AUTOMATION_PIPELINES",
  AUTOMATION_PIPELINES_WRITE: "AUTOMATION_PIPELINES_WRITE",
  AUTOMATION_PIPELINES_EXECUTE: "AUTOMATION_PIPELINES_EXECUTE",
  DATASYNC_DATA_LOGS: "DATASYNC_DATA_LOGS",
  MESSAGING_MESSAGES: "MESSAGING_MESSAGES",
  LINKER: "LINKER",

  // gearcode dashboard permissions
  GEARCODE_DIAGNOSTIC_HTTPSNIFFER: "GEARCODE_DIAGNOSTIC_HTTPSNIFFER",
  GEARCODE_DATASYNC_DATAFACTORY_LOGS: "GEARCODE_DATASYNC_DATAFACTORY_LOGS",
};

export default permissions;

import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { CopyTypography } from "@gearcode/react-components";

function ErrorDialog({ open, onClose, statusMessage, details }) {
  const { t } = useTranslation(["GcDashboard-Global-ErrorDialog"]);
  const theme = useTheme();

  const isValidJSON = (text) => {
    try {
      JSON.parse(text);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ color: theme.palette.error.main }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <ErrorOutlineIcon />
          <Typography>
            {t("AnErrorHasOccurred", "An error has occurred")}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" alignItems="start" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h5">
              {t("StatusMessage", "Status message")}:
            </Typography>
            <CopyTypography variant="body1">{statusMessage}</CopyTypography>
          </Stack>

          <Stack
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ width: "100%", overflowWrap: "break-word" }}
          >
            <Typography variant="h5">{t("Details", "Details")}:</Typography>
            {isValidJSON(details) ? (
              <Box sx={{ pl: 1 }}>
                {Object.keys(JSON.parse(details) || {}).map((key) => (
                  <Stack
                    key={key}
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography variant="h5">{key}:</Typography>
                    <CopyTypography variant="body1">
                      {JSON.stringify(JSON.parse(details)[key])}
                    </CopyTypography>
                  </Stack>
                ))}
              </Box>
            ) : (
              <Typography variant="body1">{details}</Typography>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t("Close", "Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;

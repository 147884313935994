import Logo from "ui-component/Logo";
import { Grid, Stack } from "@mui/material";

const LogoView = ({ children }) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-end"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "calc(100vh - 68px)" }}
        >
          <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sx={{ mb: 3 }}>
                <Logo />
              </Grid>
              <Grid item xs={12}>
                <Stack justifyContent="center" alignItems="center" spacing={1}>
                  {children}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LogoView;

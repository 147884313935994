// assets
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import permissions from 'permissions';
// constant
const icons = { ArticleOutlinedIcon, FactoryOutlinedIcon, StorageOutlinedIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const monitoring = {
    id: 'monitoring',
    title: 'Monitoring',
    type: 'group',
    children: [
        {
            id: "asl-logs",
            title: "ASL Log",
            type: "item",
            url: "/asl",
            icon: icons.ArticleOutlinedIcon,
            breadcrumbs: false,
            requiredPermission: permissions.ASL_INDEX_LOGS,
            feature: "ASL_LOGS",
        },
        {
            id: "feed-logs",
            title: "Feed Log",
            type: "item",
            url: "/feed",
            icon: icons.ArticleOutlinedIcon,
            breadcrumbs: false,
            requiredPermission: permissions.ASL_PRODUCT_FEED_LOGS,
            feature: "ASL_LOGS",         
        },
        {
            id: "datasync-logs",
            title: "Data Sync Log",
            type: "collapse",
            icon: icons.StorageOutlinedIcon,
            breadcrumbs: false,
            children: [
                {
                    id: "datasync-data-logs",
                    title: "Data Log",
                    type: "item",
                    url: "/datasync/data",
                    icon: icons.StorageOutlinedIcon,
                    breadcrumbs: false,
                    requiredPermission: permissions.DATASYNC_DATA_LOGS,
                    feature: "DATA_SYNC_LOGS_DATALOG",                    
                },
                {
                    id: "datasync-datafactory-logs",
                    title: "Data Factory Log",
                    type: "item",
                    url: "/datasync/datafactory",
                    icon: icons.FactoryOutlinedIcon,
                    breadcrumbs: false,
                    requiredPermission: permissions.GEARCODE_DATASYNC_DATAFACTORY_LOGS,
                    feature: "DATA_SYNC_LOGS_DATAFACTORY",
                },
            ],
        },
    ]
};

export default monitoring;

// assets
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import OutboxOutlinedIcon from '@mui/icons-material/OutboxOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import permissions from 'permissions';
// constant
const icons = { DashboardOutlinedIcon, GradeOutlinedIcon, FactoryOutlinedIcon, StorageOutlinedIcon, EmailOutlinedIcon, SendOutlinedIcon, OutboxOutlinedIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/default',
            icon: icons.DashboardOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: "scoring-profile",
            title: "Scoring",
            type: "item",
            url: "/scoring-profile",
            icon: icons.GradeOutlinedIcon,
            breadcrumbs: false,
            requiredPermission: "AZURE_SEARCH_SCORING_PROFILE_WRITE",
            feature: "SCORING_PROFILE"
        },
    ]
};

export default dashboard;

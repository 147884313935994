import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  CookieManager,
  COOKIENAME_CURRENTUSERACCOUNT,
} from "components/common/CookieManager";

import {
  USER_ACCOUNTS_LOADED,
  CURRENT_USER_ACCOUNT_CHANGED,
} from "store/actions";

import { EventBus, HttpClient } from "@gearcode/react-components/lib/Services";
import DashboardContext from "contexts/DashboardContext";
import { LinearProgress, Typography } from "@mui/material";
import LogoView from "./LogoView";

const UserAccountsLoader = ({ children }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const dashboardContext = useContext(DashboardContext);
  const { t } = useTranslation(["GcDashboard-UserAccountsLoader"]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getUserAccounts() {
      const response = await HttpClient.send(
        {
          url: `${dashboardContext.apiBaseUrl}/auth/accounts`,
        },
        { stopErrorDisptaching: true }
      );

      if (response.succeed) {
        const userAccounts = await response.json();
        if (!userAccounts.length) {
          EventBus.dispatch("Unauthorized");
          setIsLoading(false);
          return;
        }

        var storedUserAccountKey = CookieManager.getCookie(
          COOKIENAME_CURRENTUSERACCOUNT
        );

        var currentUserAccount =
          userAccounts.find((a) => a.accountKey === storedUserAccountKey) ||
          userAccounts[0];

        dispatch({ type: USER_ACCOUNTS_LOADED, userAccounts });
        dispatch({ type: CURRENT_USER_ACCOUNT_CHANGED, currentUserAccount });

        setIsLoading(false);
      }
    }
    getUserAccounts();
  }, [dashboardContext.apiBaseUrl]);

  const loader = (
    <LogoView>
      <Typography
        color={theme.palette.secondary.main}
        gutterBottom
        variant="h3"
        textAlign="center"
      >
        {t("Loading", "Loading")}...
      </Typography>
      <LinearProgress sx={{ width: "100%" }}></LinearProgress>
    </LogoView>
  );

  return <>{isLoading ? loader : children}</>;
};

export default UserAccountsLoader;

import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";

const Unauthorized = Loadable(
  lazy(() => import("components/auth/Unauthorized"))
);
const Forbidden = Loadable(lazy(() => import("components/auth/Forbidden")));

const AuthenticationRoutes = {
  path: "/auth",
  element: <MinimalLayout />,
  children: [
    {
      path: "/auth/unauthorized",
      element: <Unauthorized />,
    },
    {
      path: "/auth/forbidden",
      element: <Forbidden />,
    },
  ],
};

export default AuthenticationRoutes;

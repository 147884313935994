// assets
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import permissions from 'permissions';

// constant
const icons = { PlaylistAddCheckOutlinedIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const automation = {
    id: 'automation',
    title: 'Automation',
    type: 'group',
    children: [
        {
            id: 'pipelines',
            title: 'Pipelines',
            type: 'item',
            url: '/automation/pipelines',
            icon: icons.PlaylistAddCheckOutlinedIcon,
            breadcrumbs: false,
            requiredPermission: permissions.AUTOMATION_PIPELINES,
            feature: "AUTOMATION_PIPELINES"
        }
    ]
};

export default automation;

import { useState, useRef, useEffect, useContext } from "react";
import config from "config";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CURRENT_USER_ACCOUNT_CHANGED,
  CURRENT_USER_SCOPE_CHANGED,
} from "store/actions";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import { HttpClient } from "@gearcode/react-components/lib/Services";
import DashboardContext from "contexts/DashboardContext";
import UserAccount from "./UserAccount";
import UserScope from "./UserScope";

// assets
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const UserAccountSection = () => {
  const anchorRef = useRef(null);
  const theme = useTheme();
  var dashboardContext = useContext(DashboardContext);
  const navigate = useNavigate();
  const { t } = useTranslation(["GcDashboard-UserAccount"]);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const userAccounts = useSelector((state) => state.userAccounts.userAccounts);
  const currentUserAccount = useSelector(
    (state) => state.userAccounts.currentUserAccount
  );
  const currentUserScope = useSelector(
    (state) => state.userAccounts.currentUserScope
  );

  const handleLogout = async () => {
    await HttpClient.send({
      url: `${dashboardContext.apiBaseUrl}/auth/logout`,
    });
    navigate("/auth/unauthorized");
  };

  const handleChangeUserAccount = (account) => {
    setOpen(false);
    if (account.accountKey === currentUserAccount.accountKey) {
      return;
    }

    dispatch({
      type: CURRENT_USER_ACCOUNT_CHANGED,
      currentUserAccount: account,
    });
  };

  const handleChangeUserScope = (scope) => {
    setOpen(false);
    if (scope === currentUserScope) {
      return;
    }

    dispatch({ type: CURRENT_USER_SCOPE_CHANGED, currentUserScope: scope });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    handleChangeUserScope("");
  }, [currentUserAccount]);

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
        }}
        label={
          <Stack direction="row" spacing={2} alignItems="center">
            <UserAccount account={currentUserAccount}></UserAccount>

            {currentUserScope ? (
              <Chip
                sx={{
                  background: theme.palette.gearCodePrimary.light,
                  color: theme.palette.gearCodeText.lightPrimary,
                }}
                label={currentUserScope}
                size="small"
              ></Chip>
            ) : (
              <></>
            )}

            <SettingsOutlinedIcon
              size="1.3rem"
              sx={{ color: theme.palette.gearCodePrimary.main }}
            />
          </Stack>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ px: 2 }}>
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                      }}
                    >
                      {userAccounts.map((account) => {
                        return (
                          <ListItemButton
                            key={account.accountKey}
                            sx={{
                              borderRadius: `${config.borderRadius}px`,
                            }}
                            onClick={() => handleChangeUserAccount(account)}
                          >
                            <UserAccount account={account}></UserAccount>
                          </ListItemButton>
                        );
                      })}

                      <Divider sx={{ my: 1 }} />

                      <Box sx={{ width: "100%" }}>
                        {currentUserAccount?.scopes.length ? (
                          <>
                            <Grid
                              container
                              rowSpacing={1}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                              <Grid item xs={6}>
                                <UserScope
                                  scope={t("NoScope", "No scope")}
                                  color="grey"
                                  onClick={() => handleChangeUserScope("")}
                                />
                              </Grid>

                              {currentUserAccount.scopes.map((scope) => {
                                return (
                                  <Grid item xs={6} key={scope}>
                                    <UserScope
                                      scope={scope}
                                      onClick={() =>
                                        handleChangeUserScope(scope)
                                      }
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                            <Divider sx={{ my: 1 }} />
                          </>
                        ) : (
                          <></>
                        )}
                      </Box>

                      <ListItemButton
                        sx={{
                          borderRadius: `${config.borderRadius}px`,
                        }}
                        href={`${
                          dashboardContext.apiBaseUrl
                        }/auth/login?redirectUrl=${encodeURIComponent(
                          window.location.href
                        )}`}
                      >
                        <ListItemIcon>
                          <PersonAddAltOutlinedIcon size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">Add account</Typography>
                          }
                        />
                      </ListItemButton>

                      <ListItemButton
                        sx={{
                          borderRadius: `${config.borderRadius}px`,
                        }}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <LogoutOutlinedIcon size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">Logout</Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default UserAccountSection;

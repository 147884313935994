// assets
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import permissions from 'permissions';

// constant
const icons = { EmailOutlinedIcon, SyncAltOutlinedIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const diagnostic = {
  id: "diagnostic",
  title: "Diagnostic",
  type: "group",
  children: [
    /*{
      id: "email",
      title: "Email",
      type: "item",
      url: "/diagnostic/email",
      icon: icons.EmailOutlinedIcon,
      breadcrumbs: false,
    },*/
    {
        id: "sniffer",
        title: "Sniffer",
        type: "item",
        url: "/diagnostic/sniffer",
        icon: icons.SyncAltOutlinedIcon,
        breadcrumbs: false,
        requiredPermission: permissions.GEARCODE_DIAGNOSTIC_HTTPSNIFFER,
        feature: "HTTP_SNIFFER"        
    },
  ],
};

export default diagnostic;

import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const EmailDiagnostic = Loadable(
  lazy(() => import("components/diagnostic/EmailDiagnostic"))
);

const Sniffer = Loadable(
    lazy(() => import("components/diagnostic/Sniffer"))
    );

const DiagnosticRoutes = {
  path: "/diagnostic",
  element: <MainLayout />,
  children: [
    {
      path: "/diagnostic/email",
      element: <EmailDiagnostic />,
    },
    {
        path: "/diagnostic/sniffer",
        element: <Sniffer />,
    }
  ],
};

export default DiagnosticRoutes;

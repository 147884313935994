import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { useNavigate } from "react-router";

const PipelinesList = Loadable(
  lazy(() => import("components/automation/PipelinesList"))
);

const PipelineExecutor = Loadable(
  lazy(() =>
    import("components/automation/pipelines/Executor/PipelineExecutor")
  )
);

const PipelineEditor = Loadable(
  lazy(() => import("components/automation/pipelines/Editor/PipelineEditor"))
);

function PipelineEditorWrapper() {
  const navigate = useNavigate();
  const goToPipelines = () =>
    navigate(`/automation/pipelines`, {
      replace: true,
    });

  return <PipelineEditor onSaved={goToPipelines} onDeleted={goToPipelines} />;
}

const AutomationRoutes = {
  path: "/automation",
  element: <MainLayout />,
  children: [
    {
      path: "/automation/pipelines",
      element: <PipelinesList />,
    },
    {
      path: "/automation/pipelines/:pipelineId/execution",
      element: <PipelineExecutor />,
    },
    {
      path: "/automation/pipelines/new",
      element: <PipelineEditorWrapper />,
    },
    {
      path: "/automation/pipelines/:pipelineId/editor",
      element: <PipelineEditorWrapper />,
    },
  ],
};

export default AutomationRoutes;
